import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { resetPasswordAction } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import Message from '../components/Message'

function ResetPasswordScreen({ location, history }) {
  // const [email, setEmail] = useState('')
  const [username, setUsername] = useState("");
  const [otp, setOtp] = useState("");
  
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')


  const dispatch = useDispatch();

  const resetPassword = useSelector((state) => state.resetPassword);
  const { error, loading, message: resetPasswordMessage } = resetPassword;
  console.log("reset password:", resetPasswordMessage);
  useEffect(() => {
    console.log("reset password useeffect :", resetPasswordMessage);
    if (resetPasswordMessage) {
      console.log('redirect ')
      history.push("/profile");
    }

    console.log("reset password insdie useeffiect:", resetPasswordMessage);
  }, [history, resetPasswordMessage]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("reset password", username, otp, password);
    dispatch(resetPasswordAction(username, otp, password));
  };

  return (
    <FormContainer>
      <h1>Reset Password</h1>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="username">
          <Form.Label>Mobile number</Form.Label>
          <Form.Control
            type="text"
            maxLength='10'
            placeholder="Enter mobile number"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group controlId="otp">
          <Form.Label>OTP</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter OTP"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Enter Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <Form.Group controlId="passwordConfirm">
          <Form.Label>Confirm Password</Form.Label>
          <Form.Control
            required
            type="password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <div className="text-center m-2">
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </Form>
    </FormContainer>
  );
}

export default ResetPasswordScreen;
