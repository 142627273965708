import React from "react";
function NextPrev({ next_url, prev_url }) {
  return (
    <div className="row mx-auto my-2">
      <div className="col-4">
        {prev_url && (
          <a className="btn btn-outline-primary" href={prev_url} role="button">
            <i className="fas fa-chevron-left"></i> Prev
          </a>
        )}
      </div>
      <div className="col-4 text-center">
        <a className="btn btn-outline-primary" href={`/books/`} role="button">
          Home
        </a>
      </div>
      <div className="text-end col-4">
        {next_url && (
          <a className="btn btn-outline-primary" href={next_url} role="button">
            Next <i className="fas fa-chevron-right"></i>
          </a>
        )}
      </div>
    </div>
  );
}

export default NextPrev;
