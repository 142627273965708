import React, { useEffect } from "react";
import { Button, Card, Col, Image, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  confirmOrderPayment,
  createOrder,
  payOrder
} from "../actions/orderActions";
import Message from "../components/Message";

function PlaceOrderScreen({ history }) {
  const delivery_interval = 500;
  const POST_DELIVERY_BASE_CHARGE = 25;
  const dtdc_chart = {
    // will consider mod 500
    1: 30,
    2: 40,
    3: 50,
    4: 60,
    5: 70,
    6: 80,
    7: 90,
    8: 100,
    9: 110,
  };
  const post_chart = {
    // will consider mod 500
    1: 40,
    2: 60,
    3: 80,
    4: 95,
    5: 110,
    6: 125,
    7: 140,
    8: 155,
    9: 170,
  };

  const orderPay = useSelector((state) => state.orderPay);
  const { payment: paymentDict, error, success } = orderPay;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const orderConfirmData = useSelector((state) => state.orderConfirm);
  const { success: successConfirm, order: orderConfirm } = orderConfirmData;

  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);

  const getShippingPrice = (cartItems) => {
    try {
      const cart_items_count = cartItems.reduce(
        (acc, item) => acc + item.qty,
        0
      );
      const original_delivery_charge = cartItems.reduce(
        (acc, item) => acc + item.delivery_charges * item.qty,
        0
      );
      const total_weight = cartItems.reduce(
        (acc, item) => acc + item.weight_in_grams * item.qty,
        0
      );
      const post_delivery_charge = Math.ceil(total_weight/100) + POST_DELIVERY_BASE_CHARGE
      const delivery_charge = Math.min(
        original_delivery_charge,
        post_delivery_charge
      );
      // if (total_weight <= 100) {
      //   delivery_charge = original_delivery_charge;
      // } else if (cart.shippingAddress.deliveryMode === "dtdc") {
      //   delivery_charge = dtdc_chart[delivery_charge_key];
      // } else {
      //   if (cart_items_count === 1) {
      //     delivery_charge = original_delivery_charge;
      //   } else {
      //     const post_delivery_charge = post_chart[delivery_charge_key];
      //     delivery_charge = Math.min(
      //       original_delivery_charge,
      //       post_delivery_charge
      //     );
      //   }
      // }
      if (!delivery_charge) {
        throw "Reduce the books count from the cart"
      }
      return delivery_charge;
    } catch(err) {
      console.log("Some error occurred:", err.message);

    }
  };
  cart.itemsPrice = cart.cartItems
    .reduce((acc, item) => acc + item.original_price * item.qty, 0)
    .toFixed(2);
  cart.shippingPrice = getShippingPrice(cart.cartItems);
  cart.totalSavings = cart.cartItems
    .reduce(
      (acc, item) =>
        acc + (item.original_price - item.discounted_price) * item.qty,
      0
    )
    .toFixed(2);

  cart.discounted_total_price = cart.cartItems
    .reduce((acc, item) => acc + item.discounted_price * item.qty, 0)
    .toFixed(2);

  cart.totalPrice = (
    Number(cart.shippingPrice) + Number(cart.discounted_total_price)
  ).toFixed(2);

  useEffect(() => {
    window.scrollTo(0, 0);
    // The response should be the return from addOrderItems backend method
    const handlePaymentSuccess = async (response) => {
      dispatch(
        createOrder({
          orderItems: cart.cartItems,
          shippingAddress: cart.shippingAddress,
          itemsPrice: cart.itemsPrice,
          shippingPrice: cart.shippingPrice,
          totalSavings: cart.totalSavings,
          totalPrice: cart.totalPrice,
          order_payment_id: response.razorpay_order_id,
        })
      ).then(() => {
        dispatch(confirmOrderPayment(response));
      });
    };
    // in data we will receive an object from the backend with the information about the payment
    //that has been made by the user
    if (success) {
      // if ("out_of_stock" in paymentDict) {
      //   const book_id = paymentDict["out_of_stock"];
      //   history.push("/cart");
      //   dispatch(removeFromCart(book_id));
      // } else {
      var options = {
        key_id: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: paymentDict.payment.amount,
        currency: "INR",
        name: "Books Mitra",
        description: "Paying for book(s) order",
        config: {
          display: {
            hide: [
              {
                method: "wallet",
              },
            ],
            sequence: ["block.highlighted", "upi", "netbanking"],
            preferences: {
              show_default_blocks: true,
            },
          },
        },
        image: "", // add image url
        order_id: paymentDict.payment.id,
        // callback_url: "/api/orders/payment-success",
        handler: function (response) {
          handlePaymentSuccess(response);
        },
        prefill: {
          name: `${userInfo ? userInfo.name : cart.shippingAddress.fullName}`,
          email: `${userInfo ? userInfo.email : cart.shippingAddress.email}`,
          contact: `${
            userInfo ? userInfo.mobile : cart.shippingAddress.mobile
          }`,
        },
        notes: {'books': 'all books'},
        theme: {
          color: "#3399cc",
        },
      };
      var rzp1 = new window.Razorpay(options);

      rzp1.open();
      rzp1.on("payment.failed", function (response) {
        console.log(response.error);
      });
      // }
    }
    if (successConfirm) {
      history.push(`/order/${orderConfirm.order.id}`);
      // orderConfirm.is_guest
      //   ? history.push("/")
      //   : history.push(`/order/${orderConfirm.order.id}`);
    }
  }, [
    dispatch,
    orderConfirm,
    paymentDict,
    userInfo,
    cart,
    success,
    successConfirm,
    history,
  ]);

  const placeOrder = async () => {
    // this will load a script tag which will open up Razorpay payment card to make transactions
    const loadScript = () => {
      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/checkout.js";
      document.body.appendChild(script);
    };
    await loadScript(); // Add the script to load winows.Razorpay event
    dispatch(
      payOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
      })
    );
  };

  return (
    <div>
      <div>
        <small>
          <Link className="text-dark text-decoration-none" to={`/`}>
            Home Page
          </Link>{" "}
          &#62;{" "}
          <Link className="text-dark text-decoration-none" to={`/cart`}>
            Cart
          </Link>{" "}
          &#62;{" "}
          <Link className="text-dark text-decoration-none" to={`/shipping`}>
            Shipping Address
          </Link>{" "}
          &#62;
          <span>Place Order</span>
        </small>
      </div>
      <Row>
        <Col md={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping Address</h2>
              <pre>
                {cart.shippingAddress.fullName}
                {"-"} {cart.shippingAddress.houseNo},<br />
                {cart.shippingAddress.street}
                <br />
                {cart.shippingAddress.landmark}
                {cart.shippingAddress.taluk}, {cart.shippingAddress.district}
                <br />
                {cart.shippingAddress.stateName}, {cart.shippingAddress.pinCode}
                <br />
                {cart.shippingAddress.mobile}
              </pre>
            </ListGroup.Item>
            {/* <ListGroup.Item>
              <h4> Delivery Mode</h4>
              <pre className=" px-2 py-1">
                <h6>
                  {cart.shippingAddress.deliveryMode === "dtdc"
                    ? "DTDC Courier"
                    : "India Post"}
                </h6>
              </pre>
            </ListGroup.Item> */}

            <ListGroup.Item>
              <h2>Order Items</h2>
              {cart.cartItems.length === 0 ? (
                <Message variant="info">
                  Your cart is empty <Link to="/">Add Items</Link>{" "}
                </Message>
              ) : (
                <ListGroup variant="flush">
                  {cart.cartItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.primary_image.thumbnail}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>

                        <Col>
                          <Link to={`/books/${item.book}`}>{item.name}</Link>
                        </Col>

                        <Col md={4} as="h5">
                          {item.qty} X &#8377;{item.discounted_price} = &#8377;
                          {(item.qty * item.discounted_price).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Actual cost of Items:</Col>
                  <Col>&#8377; {cart.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Savings:</Col>
                  <Col>&#8377; {cart.totalSavings}</Col>
                </Row>
              </ListGroup.Item>
              <ListGroup.Item>
                <Row>
                  <Col>Delivery Charge:</Col>
                  <Col>&#8377; {cart.shippingPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total:</Col>
                  <Col><strong> &#8377; {cart.totalPrice} </strong></Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                {error && (
                  <Message variant="danger">
                    {error}
                    <Link
                      className="text-dark text-decoration-none"
                      to={`/cart`}
                    >
                      <i className="fas fa-shopping-cart"></i> Cart
                    </Link>
                  </Message>
                )}
              </ListGroup.Item>

              <ListGroup.Item className="d-grid gap-2">
                <Button
                  type="button"
                  className="btn-block"
                  disabled={cart.cartItems.length === 0}
                  onClick={placeOrder}
                >
                  Proceed to Pay
                </Button>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default PlaceOrderScreen;
