import React, { useEffect, useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { forgotPasswordAction } from '../actions/userActions'
import FormContainer from '../components/FormContainer'
import Loader from '../components/Loader'
import Message from '../components/Message'

function ForgotPasswordScreen({ history }) {
  const [username, setUsername] = useState("");

  const dispatch = useDispatch();

  const forgotPassword = useSelector((state) => state.forgotPassword);
  const { error, loading, message: forgotPasswordMessage } = forgotPassword;

  useEffect(() => {
    console.log("forgot password usefffect:", forgotPasswordMessage);
      if (forgotPasswordMessage === 'success'){
          console.log('redirect to reset')
          history.push('/reset-password')
      }
  }, [history, forgotPasswordMessage]);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("forgot password");
    dispatch(forgotPasswordAction(username));
  };

  return (
    <FormContainer>
      <h1>Forgot Password</h1>
      {error && <Message variant="danger">{error}</Message>}
      {loading && <Loader />}
      <Form onSubmit={submitHandler}>
        <Form.Group controlId="username">
          <Form.Label>Mobile number</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter mobile number"
            maxLength='10'
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          ></Form.Control>
        </Form.Group>

        <div className="text-center m-2">
          <Button type="submit" variant="primary">
            Submit
          </Button>
        </div>
      </Form>
    </FormContainer>
  );
}

export default ForgotPasswordScreen;
