export const ORDER_CREATE_REQUEST = "ORDER_CREATE_REQUEST";
export const ORDER_CREATE_SUCCESS = "ORDER_CREATE_SUCCESS";
export const ORDER_CREATE_FAIL = "ORDER_CREATE_FAIL";

export const ORDER_CREATE_RESET = "ORDER_CREATE_RESET";

export const ORDER_DETAILS_REQUEST = "ORDER_DETAILS_REQUEST";
export const ORDER_DETAILS_SUCCESS = "ORDER_DETAILS_SUCCESS";
export const ORDER_DETAILS_FAIL = "ORDER_DETAILS_FAIL";

export const ORDER_LIST_MY_REQUEST = "ORDER_LIST_MY_REQUEST";
export const ORDER_LIST_MY_SUCCESS = "ORDER_LIST_MY_SUCCESS";
export const ORDER_LIST_MY_FAIL = "ORDER_LIST_MY_FAIL";
export const ORDER_LIST_MY_RESET = "ORDER_LIST_MY_RESET";

export const ORDER_CONFIRM_REQUEST = "ORDER_CONFIRM_REQUEST";
export const ORDER_CONFIRM_SUCCESS = "ORDER_CONFIRM_SUCCESS";
export const ORDER_CONFIRM_FAIL = "ORDER_CONFIRM_FAIL";
export const ORDER_CONFIRM_RESET = "ORDER_CONFIRM_RESET";


export const ORDER_PAY_REQUEST = "ORDER_PAY_REQUEST";
export const ORDER_PAY_SUCCESS = "ORDER_PAY_SUCCESS";
export const ORDER_PAY_FAIL = "ORDER_PAY_FAIL";
export const ORDER_PAY_RESET = "ORDER_PAY_RESET";


export const TRACKING_NUMBER_REQUEST = "TRACKING_NUMBER_REQUEST";
export const TRACKING_NUMBER_SUCCESS = "TRACKING_NUMBER_SUCCESS";
export const TRACKING_NUMBER_FAIL = "TRACKING_NUMBER_FAIL";
export const TRACKING_NUMBER_RESET = "TRACKING_NUMBER_RESET";
