import React from "react";
function Share({ absolute_url, title }) {
  return (
    <div class="share row mx-auto mt-2 mb-1">
      <div class="col-3 col-md-1 mt-2">
          <label class="font-weight-bold font-italic">Share on:</label>
      </div>
      <div class="col-9 p-0">
          <div class="row m-auto">
              <div class="col-3 col-md-1 text-center">
                  <a class="mobileView" rel="noreferrer"
                      href={`whatsapp://send?text=${title} https://www.booksmitra.in${absolute_url}`}
                      data-action="share/whatsapp/share" target="_blank">
                      <img src="/static/images/share/whatsapp.svg" alt="Whats app" />
                  </a>
                  <a class="desktopView" rel="noreferrer"
                      href={`https://web.whatsapp.com/send?text=${title} https://www.booksmitra.in${absolute_url}`}
                      data-action="share/whatsapp/share" target="_blank">
                      <img src="/static/images/share/whatsapp.svg" alt="Whats app" />
                  </a>
              </div>
              
              <div class="col-6 col-md-2 pt-2">
                  <div class="fb-share-button" data-href={absolute_url} data-layout="button_count"
                      data-size="large">
                  </div>
              </div>
              <div class="col-3 col-md-1 text-center">
                  <a class="" rel="noreferrer"
                      href={`https://telegram.me/share/url?text=${title}&amp;url=https://www.booksmitra.in${absolute_url}`}
                      target="_blank">
                      <img src="/static/images/share/telegram.svg" alt="Telegram" />
                  </a>
              </div>
          </div>
      </div>
  </div>
  );
}

export default Share;
