import React, { useEffect } from "react";
import { Button, Card, Col, Image, ListGroup, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { getOrderDetails, trackOrderAction } from "../actions/orderActions";
import IndiaPostTracking from "../components/IndiaPostTracking";
import Loader from "../components/Loader";
import Message from "../components/Message";

function OrderScreen({ match }) {
  const orderId = match.params.id;
  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, error, loading } = orderDetails;
  // console.log("order details:", order, error);
  if (!loading && !error) {
    order.itemsPrice = order.orderItems
      .reduce((acc, item) => acc + item.original_price * item.qty, 0)
      .toFixed(2);

    order.totalPrice = order.cost.toFixed(2);
  }
  useEffect(() => {
    if (!order || order.id !== Number(orderId)) {
      dispatch(getOrderDetails(orderId));
    }
  }, [dispatch, order, orderId]);

  const trackBookOrder = useSelector((state) => state.trackOrder);
  const { loading: loadingTrackOrder, trackDetails } = trackBookOrder;
  const trackOrderHandler = () => {
    console.log("Track order:", order.tracking_number);
    dispatch(trackOrderAction(order.tracking_number)).then(() => {
      console.log("Response received", trackDetails);
      // let alert_text = document.getElementById("addRoCartAlert");
      // if (alert_text !== null) {
      //   document.getElementById("addRoCartAlert").innerHTML =
      //     "<div class='alert text-center alert-success'> <strong> Successfully added item to cart!</strong></div>";
      //   setTimeout(function () {
      //     document.getElementById("addRoCartAlert").innerHTML = "";
      //   }, 2000);
    });
  };

  return loading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger"> {error}</Message>
  ) : (
    <div>
      <div>
        <small>
          <Link className="text-dark text-decoration-none" to={`/`}>
            Home Page
          </Link>{" "}
          &#62;{" "}
          <Link className="text-dark text-decoration-none" to={`/profile`}>
            Profile/My orders
          </Link>{" "}
          &#62; <span> Order Summary </span>
        </small>
      </div>
      <Row className="m-auto">
        <h1> Order: {order.order_id} Placed successfully! </h1>
        <div className="alert alert-success mr-2">
          <p className="">
            Thanks for ordering, You will be receiving an SMS and/or Email as
            confirmation. The book will be delivered to you via{" "}
            <strong>
              {" "}
              {order.deliveryMode === "dtdc"
                ? "DTDC Courier"
                : "India Post"}{" "}
            </strong>{" "}
            within 5-7 days once order is posted. Please contact us on{" "}
            <strong>booksmitra@gmail.com</strong> or <strong>8660255805</strong>{" "}
            in case of any issues
          </p>
        </div>
        <h6> Order Date: {order.order_date.slice(0, 10)} </h6>

        <Col sm={8}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <h2>Shipping</h2>
              <pre>
                {order.shippingAddress.full_name}
                {"-"} {order.shippingAddress.house_no},<br />
                {order.shippingAddress.street}
                <br />
                {order.shippingAddress.landmark}
                {order.shippingAddress.taluk}, {order.shippingAddress.district}
                <br />
                {order.shippingAddress.state}, {order.shippingAddress.pin_code}
                <br />
                Ph: {order.shippingAddress.mobile}
              </pre>
            </ListGroup.Item>
            {order.tracking_number && (
              <ListGroup.Item>
                <Row>
                  <Col>
                    <h4>
                      Tracking number: <span>{order.tracking_number}</span>
                    </h4>
                    {loadingTrackOrder ? (
                      <Loader />
                    ) : (
                      <div className="text-center">
                        <Button
                          onClick={trackOrderHandler}
                          className="btn btn-block btn-warning"
                          type="button"
                        >
                          TrackOrder
                        </Button>
                        {trackDetails ? (
                          <div>
                            <IndiaPostTracking trackDetails={trackDetails} />
                          </div>
                        ) : (
                          <div></div>
                        )}
                         <div>
                         <a
                           href="https://www.indiapost.gov.in/_layouts/15/DOP.Portal.Tracking/TrackConsignment.aspx"
                           target="_blank"
                           rel="noreferrer"
                         >
                           Tracking url
                         </a>{" "}
                       </div>
                      </div>
                    )}
                  </Col>
                </Row>
              </ListGroup.Item>
            )}
           
            <ListGroup.Item>
              <h2>Order Items</h2>
              {order.orderItems.length === 0 ? (
                <Message variant="info">
                  Order is empty <Link to="/">Add Items</Link>{" "}
                </Message>
              ) : (
                <ListGroup variant="flush">
                  {order.orderItems.map((item, index) => (
                    <ListGroup.Item key={index}>
                      <Row>
                        <Col md={1}>
                          <Image
                            src={item.image.thumbnail}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Col>

                        <Col>
                          <Link to={`/books/${item.book}`}>{item.name}</Link>
                        </Col>

                        <Col md={4}>
                          {item.qty} X &#8377;{item.ordered_price} = &#8377;
                          {(item.qty * item.ordered_price).toFixed(2)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              )}
            </ListGroup.Item>
          </ListGroup>
        </Col>

        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>Order Summary</h2>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Actual Cost of the products:</Col>
                  <Col>&#8377; {order.itemsPrice}</Col>
                </Row>
              </ListGroup.Item>

              <ListGroup.Item>
                <Row>
                  <Col>Total amount paid:</Col>
                  <Col>&#8377; {order.totalPrice}</Col>
                </Row>
              </ListGroup.Item>
            </ListGroup>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
export default OrderScreen;
