import React from "react";
import { Card } from "react-bootstrap";

function IndiaPostTracking({ trackDetails }) {
  return trackDetails ? (
    <div>
      {trackDetails && trackDetails.booked_details ? (
        <div className="row m-auto">
          <div className="col-6">
            <span className="font-italic font-weight-bold">Booked At: </span>{" "}
            {trackDetails.booked_details.booked_at}
          </div>
          <div className="col-6">
            <span className="font-italic font-weight-bold">Date: </span>{" "}
            {trackDetails.booked_details.booked_date}
          </div>
          <div className="col-6">
            <span className="font-italic font-weight-bold">Destination: </span>{" "}
            {trackDetails.booked_details.destination}
          </div>
          <div className="col-6">
            <span className="font-italic font-weight-bold">
              Destination pincode:{" "}
            </span>
            {trackDetails.booked_details.destination_pin_code}
          </div>
        </div>
      ) : (
        <div> </div>
      )}
      <hr />

      <table className="table">
        <thead>
          <tr>
            <th scope="col" className="h6">
              Date
            </th>
            <th scope="col" className="h6 ">
              Office
            </th>
            <th scope="col" className="h6 ">
              Description
            </th>
          </tr>
        </thead>
        <tbody>
          {trackDetails.events &&
            trackDetails.events.map(function (event, index) {
              return (
                <tr>
                  <td scope="col">{event.date}</td>
                  <td scope="col">{event.office}</td>
                  <td scope="col">{event.description}</td>
                </tr>
              );
            })}
        </tbody>
      </table>
    </div>
  ) : (
    <div className="text-center">
      <span className="font-weight-bold text-danger">
        No data found! Please try again after some time
      </span>
    </div>
  );
}
export default IndiaPostTracking;
