import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { listBooks } from "../actions/bookActions";
function FilterBooks() {
  const [keyword, setKeyword] = useState("");
  const [author, setAuthor] = useState("");
  const [publisher, setPublisher] = useState("");
  const [open, setOpen] = useState(false);
  // let history = useHistory();
  const dispatch = useDispatch();

  const resetFilterForm = (e) => {
    const resetForm = document.getElementById("filterForm");
    console.log("RseT form:", resetForm);
    if( resetForm != null){
      resetForm.reset()
    }
    console.log("reset form");
    dispatch(listBooks());
  };
  const submitHandler = (e) => {
    e.preventDefault();
    const filter_args = {
      author: author,
      publisher: publisher,
    };
    dispatch(listBooks(keyword, 1, filter_args));
    // if (keyword || filter_args) {
    //   dispatch(listBooks(keyword, 1, filter_args));
    // } else {
    //   dispatch(listBooks());
    // }
  };

  return (
    <div className="bg-light my-2">
      <>
        <Button className="rounded"
          variant="warning"
          onClick={() => setOpen(!open)}
          aria-controls="collapse-filter"
          aria-expanded={open}
        >
          Filter books{" "}
          {open ? (
            <i className="fas fa-chevron-down"></i>
          ) : (
            <i className="fas fa-chevron-right"></i>
          )}
        </Button>
        <Collapse in={open}>
          <div id="collapse-filter">
            <Form onSubmit={submitHandler} id="filterForm">
              <Row>
                <Col sm={1} md={4}>
                  <Form.Group controlId="bookName">
                    <Form.Label>Book Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Book name"
                      onChange={(e) => setKeyword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={1} md={4}>
                  <Form.Group>
                    <Form.Label>Author name</Form.Label>
                    <Form.Control
                      type="text"
                      name="author"
                      placeholder="Author"
                      onChange={(e) => setAuthor(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
                <Col sm={1} md={4}>
                  <Form.Group>
                    <Form.Label>Publisher name</Form.Label>
                    <Form.Control
                      type="text"
                      name="publisher"
                      placeholder="Publisher"
                      onChange={(e) => setPublisher(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                </Col>
              </Row>
              <div className="text-center">
                <Button
                  type="button"
                  onClick={resetFilterForm}
                  variant="secondary"
                  className="p-2 mx-2 mt-2 rounded"
                >
                  Reset
                </Button>
                <Button
                  type="submit"
                  variant="primary"
                  className="p-2 mx-2 mt-2 rounded"
                >
                  Submit
                </Button>
              </div>
            </Form>
          </div>
        </Collapse>
      </>
    </div>
  );
}

export default FilterBooks;
