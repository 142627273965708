import axios from "axios";
import { CART_CLEAR_ITEMS } from "../constants/cartConstants";
import {
  ORDER_CONFIRM_FAIL,
  ORDER_CONFIRM_REQUEST,
  ORDER_CONFIRM_RESET,
  ORDER_CONFIRM_SUCCESS,
  ORDER_CREATE_FAIL,
  ORDER_CREATE_REQUEST,
  ORDER_CREATE_SUCCESS,
  ORDER_DETAILS_FAIL,
  ORDER_DETAILS_REQUEST,
  ORDER_DETAILS_SUCCESS,
  ORDER_LIST_MY_FAIL,
  ORDER_LIST_MY_REQUEST,
  ORDER_LIST_MY_SUCCESS,
  ORDER_PAY_FAIL,
  ORDER_PAY_REQUEST,
  ORDER_PAY_RESET,
  ORDER_PAY_SUCCESS,
  TRACKING_NUMBER_FAIL,
  TRACKING_NUMBER_REQUEST,
  TRACKING_NUMBER_SUCCESS
} from "../constants/orderConstants";

export const createOrder = (order) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_CREATE_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    if(userInfo && userInfo.token){
      config['headers']['Authorization'] = `Bearer ${userInfo.token}`
    }

    const { data } = await axios.post(`/api/orders/add/`, order, config);

    dispatch({
      type: ORDER_CREATE_SUCCESS,
      payload: data,
    });

    dispatch({
      type: CART_CLEAR_ITEMS,
      payload: data,
    });

    dispatch({
      type: ORDER_PAY_RESET,
    });

    localStorage.removeItem("cartItems");
  } catch (error) {
    dispatch({
      type: ORDER_CREATE_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const getOrderDetails = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_DETAILS_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    if(userInfo && userInfo.token){
      config['headers']['Authorization'] = `Bearer ${userInfo.token}`
    }

    const { data } = await axios.get(`/api/orders/${id}/`, config);

    dispatch({
      type: ORDER_DETAILS_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_DETAILS_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const payOrder = (orderDetails) => async (dispatch, getState) => {
  try {

    dispatch({
      type: ORDER_PAY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
      },
    };
    if(userInfo && userInfo.token){
      config['headers']['Authorization'] = `Bearer ${userInfo.token}`
    }

    const { data } = await axios.post(`/api/orders/pay/`, orderDetails, config);

    dispatch({
      type: ORDER_PAY_SUCCESS,
      payload: data,
    });
    dispatch({ // To avoid multiple pay requests 
      type: ORDER_PAY_RESET
    });
  } catch (error) {
    dispatch({
      type: ORDER_PAY_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};

export const confirmOrderPayment =
  (orderPlacedRequest) => async (dispatch, getState) => {
    try {
      dispatch({
        type: ORDER_CONFIRM_REQUEST,
      });

      const {
        userLogin: { userInfo },
      } = getState();
      let headers = {
        "Content-type": "application/json",
      };
      console.log("user info in confirm order::", userInfo);
      if (userInfo !== null) {
        headers["Authorization"] = `Bearer ${userInfo.token}`;
      }
      console.log('added user details:', headers)
      const config = {
        headers: headers,
      };
      const { data } = await axios.post(
        `/api/orders/payment-success/`,
        orderPlacedRequest,
        config
      );

      dispatch({
        type: ORDER_CONFIRM_SUCCESS,
        payload: data,
      });

      dispatch({
        type: CART_CLEAR_ITEMS,
        payload: data,
      });
      dispatch({
        type: ORDER_CONFIRM_RESET
      });


      localStorage.removeItem("cartItems");
    } catch (error) {
      dispatch({
        type: ORDER_CONFIRM_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
    }
  };

export const listMyOrders = () => async (dispatch, getState) => {
  try {
    dispatch({
      type: ORDER_LIST_MY_REQUEST,
    });

    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${userInfo.token}`,
      },
    };

    const { data } = await axios.get(`/api/orders/myorders/`, config);

    dispatch({
      type: ORDER_LIST_MY_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ORDER_LIST_MY_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};


export const trackOrderAction = (orderNumber) => async (dispatch) => {
  try {
    dispatch({
      type: TRACKING_NUMBER_REQUEST,
    });

    const { data } = await axios.get(`/api/orders/status/${orderNumber}/`);

    dispatch({
      type: TRACKING_NUMBER_SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: TRACKING_NUMBER_FAIL,
      payload:
        error.response && error.response.data.detail
          ? error.response.data.detail
          : error.message,
    });
  }
};
