import React, { useEffect } from "react";
import {
  Button,
  Card,
  Col,
  Form,
  Image,
  ListGroup,
  Row,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { addToCart, removeFromCart } from "../actions/cartActions";
import Message from "../components/Message";
function CartScreen({ match, location, history }) {
  const bookId = match.params.id;
  const qty = location.search ? Number(location.search.split("=")[1]) : 1;
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  useEffect(() => {
    if (bookId) {
      dispatch(addToCart(bookId, qty));
    }
  }, [dispatch, bookId, qty]);

  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
    history.push("/cart");
  };

  const checkoutHandler = () => {
    // history.push("/shipping");
    history.push("/login?redirect=shipping");
  };

  const guestCheckoutHandler = () => {
    history.push("/shipping");
    // history.push("/login?redirect=shipping");
  };

  return (
    <div>
      <div>
        <Link className="text-dark text-decoration-none" to={`/`}>
          Home Page
        </Link>{" "}
        &#62; <span> Cart </span>
      </div>
      <Row>
        <Col md={8}>
          <h1> Shopping Cart </h1>
          {cartItems.length === 0 ? (
            <Message variant="info">
              Your cart is empty <Link to="/">Explore Books</Link>
            </Message>
          ) : (
            <ListGroup variant="flush">
              {cartItems.map((item) => (
                <ListGroup.Item key={item.book}>
                  <Row>
                    <Col md={2}>
                      <Link to={`/books/${item.book}`}>
                        <Image
                          src={item.primary_image && item.primary_image.thumbnail}
                          alt={item.name}
                          fluid
                          rounded
                        />
                      </Link>
                      {/* {item.images.map(function (each_image, imageIndex) {
                      return (
                        <Link to={`/books/${item.book}`}>
                          <Image
                            src={each_image.image}
                            alt={item.name}
                            fluid
                            rounded
                          />
                        </Link>
                      );
                    })} */}
                    </Col>
                    <Col md={3}>
                      <Link to={`/books/${item.book}`}>{item.name}</Link>
                    </Col>
                    <Col md={2}>
                      <strong className="text-dark">
                        {" "}
                        &#8377;
                        {item.discounted_price
                          ? item.discounted_price.toFixed(2)
                          : ""}{" "}
                      </strong>
                      {item.original_price > item.discounted_price ? (
                        <small>
                          <del>
                            &#8377;
                            {item.original_price
                              ? item.original_price.toFixed(2)
                              : ""}
                          </del>
                        </small>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col md={3}>
                      <Form.Control
                        as="select"
                        className="form-select"
                        value={item.qty}
                        onChange={(e) =>
                          dispatch(addToCart(item.book, Number(e.target.value)))
                        }
                      >
                        <option key="1" value="1">
                          {" "}
                          1{" "}
                        </option>
                        {item.stocks_left > 1 && (
                          <option key="2" value="2">
                            {" "}
                            2{" "}
                          </option>
                        )}
                      </Form.Control>
                    </Col>
                    <Col md={1}>
                      <Button
                        type="button"
                        variant="light"
                        onClick={() => removeFromCartHandler(item.book)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <ListGroup variant="flush">
              <ListGroup.Item>
                <h2>
                  Subtotal ({cartItems.reduce((acc, item) => acc + item.qty, 0)}
                  ) items
                </h2>
                <h4>
                  &#8377;{" "}
                  {cartItems
                    .reduce(
                      (acc, item) =>
                        acc + item.qty * Number(item.discounted_price),
                      0
                    )
                    .toFixed(2)}{" "}
                  <small>
                    <del>
                      {" "}
                      (&#8377;
                      {cartItems
                        .reduce(
                          (acc, item) =>
                            acc + item.qty * Number(item.original_price),
                          0
                        )
                        .toFixed(2)}
                      )
                    </del>{" "}
                  </small>
                </h4>
              </ListGroup.Item>
            </ListGroup>
            <ListGroup.Item>
              <Link to="/">
                <Button type="button" className="btn-secondary">
                  Add more items to cart
                </Button>
              </Link>
            </ListGroup.Item>
            <ListGroup.Item className="d-grid gap-2">
              <Button
                type="button"
                className="btn-block btn-primary"
                disabled={cartItems.length === 0}
                onClick={checkoutHandler}
              >
                Proceed To Checkout
              </Button>
              {!userInfo && (
                <Button
                  type="button"
                  className="btn-block btn-warning"
                  disabled={cartItems.length === 0}
                  onClick={guestCheckoutHandler}
                >
                  Buy as Guest
                </Button>
              )}
            </ListGroup.Item>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default CartScreen;
