import { Container } from "react-bootstrap";
import { Route, BrowserRouter as Router } from "react-router-dom"; // HashRouter > With hash routing
import Footer from "./components/Footer";
import Header from "./components/Header";
import ScrollToTop from "./components/ScrollToTop";
import BookScreen from "./screens/BookScreen";
import CartScreen from "./screens/CartScreen";
import ForgotPasswordScreen from "./screens/ForgotPasswordScreen";
import HomeScreen from "./screens/HomeScreen";
import LoginScreen from "./screens/LoginScreen";
import OrderScreen from "./screens/OrderScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import ProfileScreen from "./screens/ProfileScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ResetPasswordScreen from "./screens/ResetPasswordScreen";
import ShippingScreen from "./screens/ShippingScreen";
import AboutScreen from "./screens/common/AboutScreen";
import ContactScreen from "./screens/common/ContactScreen";
import DisclaimerScreen from "./screens/common/DisclaimerScreen";
import PrivacyScreen from "./screens/common/PrivacyScreen";
import ReturnsAndRefundsScreen from "./screens/common/ReturnsAndRefundsScreen";
import TermsAndCondScreen from "./screens/common/TermsAndCondScreen";

function App() {
  return (
    <Router>
      <ScrollToTop>
      <Header />
      <main className="py-1">
        <Container fluid>
          <Route exact path="/" component={HomeScreen} />
          <Route exact path="/books" component={HomeScreen} />
          <Route exact path="/about" component={AboutScreen} />
          <Route path="/contact" component={ContactScreen} />
          <Route exact path="/privacy" component={PrivacyScreen} />
          <Route
            exact
            path="/terms-and-conditions"
            component={TermsAndCondScreen}
          />
          <Route exact path="/disclaimer" component={DisclaimerScreen} />
          <Route
            exact
            path="/returns-and-refunds"
            component={ReturnsAndRefundsScreen}
          />
          <Route path="/login" component={LoginScreen} />
          <Route exact path="/forgot" component={ForgotPasswordScreen} />
          <Route exact path="/reset-password" component={ResetPasswordScreen} />
          <Route exact path="/register" component={RegisterScreen} />
          <Route exact path="/profile" component={ProfileScreen} />
          <Route path="/shipping" component={ShippingScreen} />
          <Route path="/placeorder" component={PlaceOrderScreen} />
          <Route path="/order/:id" component={OrderScreen} />
          <Route exact path="/books/:id" component={BookScreen} />
          <Route exact path="/books/:id/:name" component={BookScreen} />
          <Route path="/cart/:id?" component={CartScreen} />
        </Container>
      </main>
      <Footer />
      </ScrollToTop >
    </Router>
  );
}

export default App;
