import React  from "react";
function ReturnsAndRefundsScreen() {
  return <div>
    <h4>Returns and Refund policy</h4>
<p>Since our Website offers non-tangible, irrevocable services we do not provide refunds after the product is purchased,
    which you acknowledge prior to purchasing the product on the Website.</p>
<p>For books(product) ordered online, since we will be
    shipping through India Post, it is users responsibility
    to track the product and receive from postal
    department as we will be sharing the consignment(tracking) number.
    We don't have any options for returning the books or refunding the amount once ordered.
</p>
<h5>Contacting us</h5>
<p>If you would like to contact us concerning any matter relating to this Refund Policy, you may do so via the <a
        target="_blank" rel="noreferrer" href="https://www.booksmitra.in/contact">contact form</a> or send an email to
    booksmitra@gmail.com</p>

<p>This document was last updated on October 15, 2020</p>

  </div>;
}

export default ReturnsAndRefundsScreen;
