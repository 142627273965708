import React, { useState, useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { saveShippingAddress } from "../actions/cartActions";
import FormContainer from "../components/FormContainer";

function ShippingScreen({ history }) {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [fullName, setFullName] = useState(shippingAddress.fullName);
  const [houseNo, setHouseNo] = useState(shippingAddress.houseNo);
  const [street, setStreet] = useState(shippingAddress.street);
  const [taluk, setTaluk] = useState(shippingAddress.taluk);
  const [district, setDistrict] = useState(shippingAddress.district);
  const [stateName, setStateName] = useState(
    shippingAddress ? shippingAddress.stateName : "Karnataka"
  );
  const [pinCode, setPinCode] = useState(shippingAddress.pinCode);
  const [landmark, setLandmark] = useState(shippingAddress.landmark);
  const [mobile, setMobile] = useState(shippingAddress.mobile);
  const [email, setEmail] = useState("");
  const [deliveryMode, setDeliveryMode] = useState(shippingAddress.deliveryMode);

  const submitHandler = (e) => {
    e.preventDefault();
    console.log("on form submit:", deliveryMode);
    let confirm_response = true;
    if (deliveryMode === "dtdc") {
      confirm_response = window.confirm(
        "ಈ ಆಯ್ಕೆಯಲ್ಲಿ ನಿಮಗೆ ಪುಸ್ತಗಳನ್ನು DTDC ಕೊರಿಯರ್ ಸೇವೆ ಇರುವ ಪ್ರದೇಶದಲ್ಲಿ ಮಾತ್ರ ತಲುಪಿಸಲಾಗುವದು, ಅದು ಕೇವಲ 2 ರಿಂದ 4 ದಿನಗಳಲ್ಲಿ, ಖಚಿತಪಡಿಸಿ...!"
      );
    }
    console.log("confirm_response from cinfri:", confirm_response);
    if (confirm_response === true) {
      dispatch(
        saveShippingAddress({
          fullName,
          houseNo,
          street,
          taluk,
          district,
          stateName,
          pinCode,
          landmark,
          mobile,
          email,
          deliveryMode,
        })
      );
      history.push("/placeorder");
    }
  };

  return (
    <div>
      <div>
        <small>
          <Link className="text-dark text-decoration-none" to={`/`}>
            Home Page
          </Link>{" "}
          &#62;{" "}
          <Link className="text-dark text-decoration-none" to={`/cart`}>
            Cart
          </Link>{" "}
          &#62; <span> Shipping Address </span>
        </small>
      </div>

      <FormContainer>
        {/* <CheckoutSteps step1 step2 /> */}
        <h1>Shipping</h1>
        <Form onSubmit={submitHandler}>
          <Form.Group controlId="fullName">
            <Form.Label>
              Full name
              <span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter full name"
              maxLength="30"
              value={fullName ? fullName : ""}
              onChange={(e) => setFullName(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="houseNo">
            <Form.Label>House No</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter house no"
              maxLength="20"
              value={houseNo ? houseNo : ""}
              onChange={(e) => setHouseNo(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="street">
            <Form.Label>
              Street / Village<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter Street / Area / Village"
              maxLength="30"
              value={street ? street : ""}
              onChange={(e) => setStreet(e.target.value)}
            ></Form.Control>
          </Form.Group>

          <Form.Group controlId="taluk">
            <Form.Label>
              Area/Taluk<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter taluk"
              maxLength="30"
              value={taluk ? taluk : ""}
              onChange={(e) => setTaluk(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="district">
            <Form.Label>
              District<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              maxLength="30"
              placeholder="Enter district"
              value={district ? district : ""}
              onChange={(e) => setDistrict(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="stateName">
            <Form.Label>
              State<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              placeholder="Enter state"
              maxLength="30"
              value={stateName ? stateName : "Karnataka"}
              onChange={(e) => setStateName(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="pinCode">
            <Form.Label>
              Pin code<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              type="text"
              maxLength="6"
              placeholder="Enter pin code"
              value={pinCode ? pinCode : ""}
              onChange={(e) => setPinCode(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="landmark">
            <Form.Label>Landmark</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter landmark"
              maxLength="30"
              value={landmark ? landmark : ""}
              onChange={(e) => setLandmark(e.target.value)}
            ></Form.Control>
          </Form.Group>
          <Form.Group controlId="mobile">
            <Form.Label>
              Mobile number<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              required
              maxLength="10"
              type="text"
              placeholder="Enter mobile number"
              value={mobile ? mobile : ""}
              onChange={(e) => setMobile(e.target.value)}
            ></Form.Control>
          </Form.Group>
          {userInfo ? (
            ""
          ) : (
            <Form.Group controlId="email">
              <Form.Label>
                Email address<span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                required
                type="email"
                placeholder="Enter email address"
                maxLength="40"
                value={email ? email : ""}
                onChange={(e) => setEmail(e.target.value)}
              ></Form.Control>
            </Form.Group>
          )}
          {/* <Form.Group controlId="deliveryMode">
            <Form.Label>
              Delivery mode<span className="text-danger">*</span>
            </Form.Label>
            <Form.Control
              as="select"
              required
              value={deliveryMode}
              onChange={(e) => {
                setDeliveryMode(e.target.value);
              }}
            >
              <option value="">-----</option>
              <option value="post">India Post(ಅಂಚೆ)</option>
            </Form.Control>
          </Form.Group> */}

          <div className="text-center m-2">
            <Button type="submit" variant="primary">
              Continue
            </Button>
          </div>
        </Form>
      </FormContainer>
    </div>
  );
}

export default ShippingScreen;
