import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Carousel,
  Col,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  bookNotifyMeAction,
  createBookReview,
  listBookDetails,
} from "../actions/bookActions";
import { addToCart } from "../actions/cartActions";
import Book from "../components/Book";
import Loader from "../components/Loader";
import Message from "../components/Message";
import NextPrev from "../components/NextPrev";
import Share from "../components/Share";
import Rating from "../components/Rating";

function BookScreen({ match, history }) {
  const [qty, setQty] = useState(1);
  const [star, setStar] = useState(0);
  const [comment, setComment] = useState("");
  const [notifyMeMessage, setNotifyMeMessage] = useState("");

  const dispatch = useDispatch();
  const bookDetails = useSelector((state) => state.bookDetails);
  const { error, loading, book, next_book, prev_book } = bookDetails;
  let discount = 0;
  if (book != null) {
    discount = Math.ceil(
      ((book.original_price - book.discounted_price) / book.original_price) *
        100
    );
  }

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const bookReviewCreate = useSelector((state) => state.bookReviewCreate);
  const {
    loading: loadingBookReview,
    error: errorBookReview,
    success: successBookReview,
  } = bookReviewCreate;

  const bookNotifyMe = useSelector((state) => state.bookNotifyMe);
  const { success: successBookNotifyMe } = bookNotifyMe;
  useEffect(() => {
    dispatch(listBookDetails(match.params.id));

    if (successBookNotifyMe === true) {
      setNotifyMeMessage("Notify me request received!");
      setTimeout(() => setNotifyMeMessage(""), 3000);
    }
  }, [dispatch, match, successBookNotifyMe]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createBookReview(match.params.id, {
        star,
        comment,
      })
    );
  };

  const buyNowHandler = () => {
    history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  const addToCartHandler = () => {
    if (match.params.id) {
      dispatch(addToCart(match.params.id, qty)).then(() => {
        let alert_text = document.getElementById("addRoCartAlert");
        if (alert_text !== null) {
          document.getElementById("addRoCartAlert").innerHTML =
            "<div class='alert text-center alert-success'> <strong> Successfully added item to cart!</strong></div>";
          setTimeout(function () {
            if (alert_text !== null) {
              document.getElementById("addRoCartAlert").innerHTML = "";
            }
          }, 2000);
        }
      });
    }

    // history.push(`/cart/${match.params.id}?qty=${qty}`);
  };

  const notifyMeHandler = (e) => {
    setNotifyMeMessage("");
    dispatch(bookNotifyMeAction(match.params.id));
  };
  return (
    <div>
      <div id="addRoCartAlert"> </div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          {book.seo ? `${book.seo.substr(0, 35)} | Books Mitra` : "Books Mitra"}{" "}
        </title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#000000" />
        <meta name="description" content={book.name} />
        <meta name="keywords" content={`${book.seo} ${book.keywords}`} />
        <link rel="canonical" href="http://booksmitra.in" />
        <meta
          property="og:title"
          content={book.name && book.name.substring(0, 35)}
        />
        <meta property="og:url" content={window.location.href} />
        <meta
          property="og:description"
          content={book.description && book.description.substring(0, 50)}
        />
        <meta
          property="og:image"
          content={book.primary_image && book.primary_image.image}
        />
        <meta property="og:type" content="website" />
      </Helmet>
      <div className="mb-2">
        <small>
          <Link className="text-dark text-decoration-none" to={`/`}>
            Home Page
          </Link>{" "}
          &#62; <span> {book.name} </span>
        </small>
      </div>
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">
          {error} <Link to={`/`}>Visit Home Page</Link>{" "}
        </Message>
      ) : (
        <div className="mt-2">
          <NextPrev
            prev_url={prev_book && `/books/${prev_book.id}/${prev_book.slug}`}
            next_url={next_book && `/books/${next_book.id}/${next_book.slug}`}
          />
          <Share absolute_url={match.url} title={book.name}/>
          <Row>
            <Col md={3} className="position-relative">
              {discount > 0 ? (
                <div className="product-image-top-right mb-1 border text-white py-2 px-1 rounded-circle bg-success">
                  <span> {discount}% off</span>
                </div>
              ) : (
                ""
              )}
              {book && (
                <Carousel pause="hover" interval={5000} className="bg-white">
                  {book.images.map((book_image) => (
                    <Carousel.Item key={book_image.id} className="bookDetails">
                      <Link to={`/books/${book.id}`}>
                        <Card.Img
                          src={book_image.image}
                          className="border bookDetailImage rounded"
                        />
                      </Link>
                    </Carousel.Item>
                  ))}
                </Carousel>
              )}
            </Col>
            <Col md={6}>
              <ListGroup variant="flush">
                <ListGroup.Item key={book.name}>
                  <h3 className="pb-0">{book.name}</h3>
                  <Row>
                    <Col xs={5}>
                      {book.author ? (
                        <h6>
                          <span className="d-none d-sm-inline">
                            {" "}
                            <i className="fw-bold">Author</i>:{" "}
                          </span>
                          {book.author}
                        </h6>
                      ) : (
                        ""
                      )}
                    </Col>
                    <Col xs={7}>
                      {book.reviews_count ? (
                        <Rating
                          value={book.average_review_rating}
                          text={`${book.reviews_count} reviews`}
                          color={"#f8e825"}
                        />
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>

                {book.publisher ? (
                  <ListGroup.Item key={book.publisher} as="h6">
                    <i className="fw-bold">Publisher</i>: {book.publisher}
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                <ListGroup.Item key={book.original_price} as="h5">
                  <i className="fw-bold">Price:</i>&nbsp; &#8377;
                  {book.discounted_price}{" "}
                  {book.original_price > book.discounted_price ? (
                    <small className="text-muted">
                      (<del> &#8377; {book.original_price}</del>)
                    </small>
                  ) : (
                    ""
                  )}
                  {discount > 0 ? (
                    <span className="text-success ">
                      <strong> {discount}% off </strong>
                    </span>
                  ) : (
                    ""
                  )}
                </ListGroup.Item>
                {book.language ? (
                  <ListGroup.Item key={book.language} as="p">
                    <i className="fw-bold">Language</i>:{" "}
                    {book.language === "kn" ? "Kannada" : "English"}
                  </ListGroup.Item>
                ) : (
                  ""
                )}
                {book.description ? (
                  <ListGroup.Item key={book.description}>
                    <i className="fw-bold">About the book:</i>
                    <div
                      dangerouslySetInnerHTML={{ __html: book.description }}
                    />
                  </ListGroup.Item>
                ) : (
                  ""
                )}

                {book.number_of_pages ? (
                  <ListGroup.Item key={book.number_of_pages} as="h6">
                    <i>Number of Pages</i>: {book.number_of_pages}
                  </ListGroup.Item>
                ) : (
                  ""
                )}
              </ListGroup>
            </Col>
            <Col md={3}>
              <Card>
                <ListGroup variant="flush">
                  <ListGroup.Item key={book.original_price}>
                    <Row>
                      <Col>Price:</Col>
                      <Col>
                        <strong className="text-success">
                          {" "}
                          &#8377;
                          {book.discounted_price
                            ? book.discounted_price.toFixed(2)
                            : ""}{" "}
                        </strong>
                        {book.original_price > book.discounted_price ? (
                          <small className="text-muted">
                            <del>
                              &#8377;
                              {book.original_price
                                ? book.original_price.toFixed(2)
                                : ""}
                            </del>
                          </small>
                        ) : (
                          ""
                        )}
                        {discount > 0 ? (
                          <div className="text-muted">
                            Savings &#8377;
                            {book.original_price && book.discounted_price
                              ? (
                                  book.original_price - book.discounted_price
                                ).toFixed(2)
                              : ""}
                          </div>
                        ) : (
                          ""
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  <ListGroup.Item key="status">
                    <Row>
                      <Col>Status:</Col>
                      <Col>
                        {book.stocks_left > 0 ? (
                          <span className="text-success">
                            <strong> In Stock </strong>
                          </span>
                        ) : book.stocks_left > 0 ? (
                          <span className="text-warning">
                            <strong>Only {book.stocks_left} available </strong>
                          </span>
                        ) : (
                          <span className="text-danger">
                            <strong> Out of Stock </strong>
                          </span>
                        )}
                      </Col>
                    </Row>
                  </ListGroup.Item>

                  {book.stocks_left > 0 && (
                    <ListGroup.Item key="stocks_left">
                      <Row className="my-1">
                        <Col>Qty:</Col>
                        <Col className="text-center">
                          <Form.Control
                            as="select"
                            className="form-select"
                            value={qty}
                            onChange={(e) => setQty(e.target.value)}
                          >
                            <option key="1" value="1">
                              {" "}
                              1{" "}
                            </option>
                            {book.stocks_left > 1 && (
                              <option key="2" value="2">
                                {" "}
                                2{" "}
                              </option>
                            )}
                          </Form.Control>
                        </Col>
                      </Row>
                    </ListGroup.Item>
                  )}

                  <ListGroup.Item className="d-grid gap-2" key="buy_now">
                    {book.stocks_left ? (
                      <React.Fragment>
                        <Button
                          onClick={addToCartHandler}
                          className="btn btn-block"
                          type="button"
                        >
                          Add to Cart
                        </Button>
                        <Button
                          onClick={buyNowHandler}
                          className="btn btn-block btn-warning"
                          type="button"
                        >
                          Buy Now
                        </Button>
                      </React.Fragment>
                    ) : userInfo ? (
                      <div className="text-center">
                        {notifyMeMessage ? (
                          <Message variant="info">{notifyMeMessage}</Message>
                        ) : (
                          ""
                        )}
                        <Button
                          onClick={notifyMeHandler}
                          className="btn btn-warning"
                          type="button"
                        >
                          Notify Me
                        </Button>
                      </div>
                    ) : (
                      <p>
                        Kindly <Link to="/login">Login</Link> to request for
                        notifying !
                      </p>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
          </Row>
          <NextPrev
            prev_url={prev_book && `/books/${prev_book.id}/${prev_book.slug}`}
            next_url={next_book && `/books/${next_book.id}/${next_book.slug}`}
          />

          <Row>
            <h4> Related books</h4>
            {book.related_books.map((rel_book) => (
              <Col key={rel_book.id} xs={6} sm={4} lg={3} xl={2}>
                <Book book={rel_book} />
              </Col>
            ))}
          </Row>

          <Row>
            <Col md={6}>
              {book.reviews.length > 0 && (<h3 className="text-center mt-2">Reviews</h3>)}
              <ListGroup variant="flush">
                <ListGroup.Item key="review">
                  <h4>Write a review</h4>

                  {loadingBookReview && <Loader />}
                  {successBookReview && (
                    <Message variant="success">Review Submitted</Message>
                  )}
                  {errorBookReview && (
                    <Message variant="danger">{errorBookReview}</Message>
                  )}

                  {userInfo ? (
                    <Form onSubmit={submitHandler}>
                      <Form.Group controlId="star">
                        <Form.Label>Rating</Form.Label>
                        <Form.Control
                          as="select"
                          value={star}
                          onChange={(e) => setStar(e.target.value)}
                        >
                          <option value="">Select...</option>
                          <option value="5">5 - Excellent</option>
                          <option value="4">4 - Very Good</option>
                          <option value="3">3 - Good</option>
                          <option value="2">2 - Fair</option>
                          <option value="1">1 - Poor</option>
                        </Form.Control>
                      </Form.Group>

                      <Form.Group controlId="comment">
                        <Form.Label>Review</Form.Label>
                        <Form.Control
                          as="textarea"
                          row="5"
                          value={comment}
                          onChange={(e) => setComment(e.target.value)}
                        ></Form.Control>
                      </Form.Group>

                      <Button
                        disabled={loadingBookReview}
                        type="submit"
                        className="btn btn-block mt-2"
                        variant="primary"
                      >
                        Submit
                      </Button>
                    </Form>
                  ) : (
                    <Message variant="info">
                      Please{" "}
                      <Link
                        to={match ? `/login?redirect=${match.url}` : "/login"}
                      >
                        login
                      </Link>{" "}
                      to write a review
                    </Message>
                  )}
                </ListGroup.Item>
                {book.reviews.length > 0 && (<h5 className="text-center mt-2">Recent reviews</h5>)}
                {book.reviews.map((review) => (
                    <ListGroup.Item key={review.id}>
                      <strong>{review.name}</strong>
                      <Rating value={review.star} color="#f8e825" />
                      <p>{review.review_date.substring(0, 10)}</p>
                      <p>{review.comment}</p>
                    </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
          </Row>
        </div>
      )}
    </div>
  );
}

export default BookScreen;
