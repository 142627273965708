export const BOOK_LIST_REQUEST = "BOOK_LIST_REQUEST";
export const BOOK_LIST_SUCCESS = "BOOK_LIST_SUCCESS";
export const BOOK_LIST_FAIL = "BOOK_LIST_FAIL";


export const BOOK_DETAILS_REQUEST = "BOOK_DETAILS_REQUEST";
export const BOOK_DETAILS_SUCCESS = "BOOK_DETAILS_SUCCESS";
export const BOOK_DETAILS_FAIL = "BOOK_DETAILS_FAIL";

export const BOOK_FEATURED_REQUEST = "BOOK_FEATURED_REQUEST";
export const BOOK_FEATURED_SUCCESS = "BOOK_FEATURED_SUCCESS";
export const BOOK_FEATURED_FAIL = "BOOK_FEATURED_FAIL";


export const BOOK_CREATE_REVIEW_REQUEST = 'BOOK_CREATE_REVIEW_REQUEST'
export const BOOK_CREATE_REVIEW_SUCCESS = 'BOOK_CREATE_REVIEW_SUCCESS'
export const BOOK_CREATE_REVIEW_FAIL = 'BOOK_CREATE_REVIEW_FAIL'
export const BOOK_CREATE_REVIEW_RESET = 'BOOK_CREATE_REVIEW_RESET'


export const BOOK_NOTIFY_ME_REQUEST = 'BOOK_NOTIFY_ME_REQUEST'
export const BOOK_NOTIFY_ME_SUCCESS = 'BOOK_NOTIFY_ME_SUCCESS'
export const BOOK_NOTIFY_ME_FAIL = 'BOOK_NOTIFY_ME_FAIL'
export const BOOK_NOTIFY_ME_RESET = 'BOOK_NOTIFY_ME_RESET'