import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
// import { useHistory } from "react-router-dom";
import { listBooks } from "../actions/bookActions";

function SearchBox() {
  const [keyword, setKeyword] = useState("");

  // let history = useHistory();
  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    if (keyword) {
      dispatch(listBooks(keyword, 1));
      // history.push(`/?keyword=${keyword}&page=1`);
    } else {
      dispatch(listBooks());
      // history.push(history.push(history.location.pathname));
    }
  };

  return (
    <Form onSubmit={submitHandler} className="d-flex">
      <Form.Control
        type="text"
        name="q" placeholder="Search books"
        onChange={(e) => setKeyword(e.target.value)}
        className="mr-sm-2 ml-sm-5"
      ></Form.Control>

      <Button type="submit" variant="outline-success" className="p-2 mx-2">
        Submit
      </Button>
    </Form>
  );
}

export default SearchBox;
